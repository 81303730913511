import { Axios } from 'axios';

class GeckoApi {
  constructor() {
    this.client = new Axios({
      headers: {
        'accept': 'application/json',
        'x-cg-pro-api-key': process.env.REACT_APP_COIN_GECKO_API_KEY,
      },
    });
  }

//   public async post(url: string, data: any): Promise<any> {
//     this.client.post(process.env.GECKO_URL + url, JSON.stringify(data));  
//   }
    async get(url, params) {
        return this.client.get(process.env.REACT_APP_COIN_GECKO_API_HOST + url, { params });
    }
}
const gecko_api =  new GeckoApi()

export default gecko_api;