import React from 'react';

const ShortenedValue = ({ fullValue, start=5, end=-4, link = false, linkPrefix = ''}) => {
    const shortValue = `${fullValue.slice(0, start)}...${fullValue.slice(end)}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(fullValue).then(() => {
            alert(`Copied to clipboard: ${fullValue}`);
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };
    const openLink = () => {
        window.open(linkPrefix + fullValue, '_blank');
    };

    return (
        <div
            className="copy-button"
            title="Click to copy full value"
            onClick={!link ? copyToClipboard : openLink}
        >
            {shortValue}
        </div>
    );
    
        
};

export default ShortenedValue;