
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// Example async thunk to fetch data from an API using axios
export const fetchPools = createAsyncThunk('wonton/fetchPools', async ({filter}) => {
  const response = await axios.get(`https://prod-backend-wonton-fd36236b22c2.herokuapp.com/api/${filter}`);
  try{
    return response.data;
  }catch{
    return []
  }
});



const initialState = {
  data: [],
  status: 'idle', 
  error: null,
};

export const wontonSlice = createSlice({
  name: 'wonton_slice',
  initialState,
  reducers: {
    setStatusLoading: (state, action) => {
      state.status = action.payload ? 'loading' : 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPools.pending, (state) => {
        if(state.page === 1)
          state.status = 'loading';
        else {
          state.status = 'scroll_loading';
        }
      })
   
      .addCase(fetchPools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPools.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
     
  },
});

export const { setStatusLoading } = wontonSlice.actions;

export default wontonSlice.reducer;
