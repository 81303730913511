import { Axios } from 'axios';


class BackendApi {
  constructor() {
    console.log(localStorage.token)
    this.client = new Axios({
      headers: {
        Authorization: localStorage.token,
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Headers': 'access-control-allow-origin,content-type',
        'Access-Control-Request-Method': 'POST'
      },
      baseURL: process.env.REACT_APP_BACKEND_HOST
    });
  }

}
const backend_api_client =  (new BackendApi()).client

export default backend_api_client;