import React, { useEffect } from 'react';
import './index.css';
import FooterMain from '../../components/footer-main';
import { ReactComponent as LogoCopy } from '../../assets/copy.svg';
import { countReferals, login} from '../../store/profile';
import { useSelector, useDispatch } from 'react-redux';

const Frens = () => {
    const dispatch = useDispatch();
    const count = useSelector((state) => state.profileReducer.referalCount);
    const token = useSelector((state) => state.profileReducer.token);

    useEffect(()=>{
        dispatch(countReferals(localStorage.telegramUserId))
    }, [dispatch, token])
    useEffect(()=>{
        dispatch(login(localStorage.telegramUserId))
        function preventCollapse(event) {
            if (window.scrollY === 0) {
              window.scrollTo(0, 1);
            }
          }
          const scrollableElement = document.querySelector(".scrollable-element");
          scrollableElement.addEventListener("touchstart", preventCollapse);
    }, [dispatch])
    const copyToClipboard = () => {
        if(localStorage.telegramUserId){
            navigator.clipboard.writeText(`https://t.me/${process.env.REACT_APP_BOT_USERNAME}?start=rodnoiId${localStorage.telegramUserId}`).then(() => {
                alert(`Copied to clipboard`);
            }, (err) => {
                console.error('Could not copy text: ', err);
            });
        }
    };
    return (
        <div className='scrollable-element'>
            <div className="frens">
                <div className="balance-section">
                    {/* <div className="balance">
                        <LogoPoints/>
                        <span>0</span>
                    </div> */}
                    <p className="comeback-message">Invite your friends to earn more</p>
                <div className='referal-block'>
                    <div className='referal-block-title'>referral rewards</div>
                    <div className='referal-block-content'>
                        <div className='referal-block-invite-count'><span className="fren">{count}</span><span  className="fren-invite">invited</span></div>
                        <div className='referal-block-invite-count'><span className="fren">Rewards</span><span  className="fren-invite">calculating...</span></div>
                    </div>
                    <div className='referal-block-invite-copy' onClick={copyToClipboard}><LogoCopy fill={'#fff'}/><p>Copy Referral Link</p></div>
                </div>
            
                <p className="comeback-message-2"> You earn 30% of your referees’ trading fees</p>

                </div>
                <FooterMain />
            </div>
        </div>

    );
};

export default Frens;