
var SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];

export function nFormatter(number){

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) return Number(number).toFixed(2);

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}


export function formatPrices(number) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 10,
        maximumFractionDigits: 10,
    });

   return formatter.format(number);
}

export function formatWithSubscript(value) {
  
    if(isNaN(value)){return value}

    const match = value.match(/0\.000+(.*)/);

    if (match) {
        const leadingZerosCount = match[0].split('0').length - 4;
        const significantDigits = match[1];

        // Convert leadingZerosCount to subscript
        const subscriptNumbers = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉'];
        const subscriptString = String(leadingZerosCount)
            .split('')
            .map(digit => subscriptNumbers[parseInt(digit, 10)])
            .join('');

        return `0.0${subscriptString}${significantDigits.slice(0, 4)}`;
    } else {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
        });
    
        const formattedValue = formatter.format(value);
        return formattedValue;
    }
}

export function univarsalFormatNumber(number) {
    if(isNaN(number)){return number}
    if(number == null){return number}
    if(Number(number)< 1){
       return formatWithSubscript(number)
    }
    else{
       return nFormatter(number)
    }
}

export function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return `${interval}years ago`;
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return `${interval}months ago`;
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return `${interval}days ago`;
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return `${interval}hours ago`;
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return `${interval}minutes ago`;
    }

    return `${Math.floor(seconds)}s ago`;
}

// Example usage
// Output will depend on the current date and time
