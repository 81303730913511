import React, { useEffect, useState, useCallback } from 'react';
import {Link} from 'react-router-dom'
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import {nFormatter, formatWithSubscript, timeAgo} from '../../utils/number';
import ShortenedValue from '../../utils/copy';
import { sendTokenInfo } from '../../store/socket';
import {  fetchPool, fetchTokenInfo } from '../../store/coin_gecko';
import ReadMoreReact from 'read-more-react';
import FooterToken from '../../components/footer-token';

function PoolInfo() {

    const dispatch = useDispatch();
    const tg = window.Telegram.WebApp;
    const {data, included} = useSelector((state) => state.geckoReducer.selected);
    const status = useSelector((state) => state.socketReducer.status);
    const token_info = useSelector((state) => state.geckoReducer.token_info);

    const [selectedTimeFrame, setSelectedTimeFrame] = useState('h24');
    const {
        price_change_percentage, 
        name: trade_name, base_token_price_usd, market_cap_usd, 
        reserve_in_usd, volume_usd, fdv_usd, 
        base_token_price_native_currency, transactions,
        pool_created_at,
        address: pool_address
    } = data?.attributes;
    const { name, address, image_url } = included[0]?.attributes;

    const onTrade = () => {
        const id = localStorage.telegramUserId
        
        if(id){
            tg.MainButton.showProgress(true)	
            dispatch(sendTokenInfo({tokenAddress: address, telegram_id: id}))
            setInterval(() => {
                tg.close();
            }, 3000)
        }
    }

    const { description, telegram_handle, twitter_handle, websites, discord_url} = token_info?.data?.attributes || {};

    useEffect(()=>{
        dispatch(fetchTokenInfo({network: 'ton', address: JSON.parse(localStorage.selected_pool).included[0].attributes.address}))

        if(!data?.attributes?.address){
            dispatch(fetchPool({net: 'ton', address: JSON.parse(localStorage.selected_pool).data.attributes.address}));
        }
    }, [])

  
    const sendDataToTelegram = useCallback(() => {
        onTrade()
      }, [])
    
      useEffect(() => {
        tg.onEvent('mainButtonClicked', sendDataToTelegram)
        return () => {
          tg.offEvent('mainButtonClicked', sendDataToTelegram)
        }
      }, [sendDataToTelegram])
    


    tg.MainButton.setText(`Trade ${trade_name}`);

    if(!data?.attributes?.address) {
        return <div className='loader'></div>
    }
    const openLink = (href) => {
        window.open(href, '_blank');
    };

    return (
        <div className="pool-info">
              <div className="header">
            <Link  to='/' className="back-button">←</Link>
            <div className="token-info">
                <img src={image_url.replace('/small/', '/large/')} alt="token" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="https://dummyimage.com/400x400/f5f5f5/000000.png&text="+name[0];
                }} className="token-logo" />
                <div className="token-details">
                    <h1>{name}</h1>
                    <div className="token-icons">
                        {telegram_handle && <div onClick={()=>{openLink("https://t.me/"+telegram_handle)}}><img src='/assets/telegram.png' alt='telegram'></img></div>}
                        {twitter_handle && <div onClick={()=>{openLink("https://x.com/"+twitter_handle)}}><img src='/assets/twitter.png' alt='twitter'></img></div>}
                        {discord_url && <div onClick={()=>{openLink(discord_url)}}><img src='/assets/discord.png' alt='twitter'></img></div>}
                        {websites?.length ? <div onClick={()=>{openLink(websites.find(i=> !i.includes('https://t.me/')))}} ><img src='/assets/website.png' alt='website'></img></div>:''}
                    </div>
                </div>
            </div>
            {/* <div className='description'>{description}</div> */}
            {description && <ReadMoreReact key={description} className='description' text={description} />}

        </div>
         <div className="main-content">
      
            <div className="price-info">
                <div className="price-box">
                    <span className="label">PRICE USD</span>
                    <span className="value">${formatWithSubscript(base_token_price_usd)}</span>
                </div>
                <div className="price-box">
                    <span className="label">PRICE</span>
                    <span className="value">{formatWithSubscript(base_token_price_native_currency)} TON</span>
                </div>
            </div>
            <div className="stats-info">
                <div className="stat-box">
                    <span className="label">LIQUIDITY</span>
                    <span className="value">${nFormatter(reserve_in_usd)}</span>
                </div>
                <div className="stat-box">
                    <span className="label">FDV</span>
                    <span className="value">${nFormatter(fdv_usd)}</span>
                </div>
                <div className="stat-box">
                    <span className="label">MKT CAP</span>
                    <span className="value">${nFormatter(market_cap_usd) || '~'}</span>
                </div>
            </div>
            <div className="performance-info">
                <div className="performance-box" style={{background: selectedTimeFrame === 'm5' ?  '#343439' : 'transparent'}} onClick={()=>setSelectedTimeFrame('m5')}>
                    <span className="label">5M</span>
                    <span className={price_change_percentage.m5 > 0 ? 'green' : "red"}>{price_change_percentage.m5}%</span>
                </div>
                <div className="performance-box"  style={{background: selectedTimeFrame === 'h1' ?  '#343439' : 'transparent'}} onClick={()=>setSelectedTimeFrame('h1')}>
                    <span className="label">1H</span>
                    <span className={price_change_percentage.h1 > 0 ? 'green' : "red"}>{price_change_percentage.h1}%</span>
                </div>
                {/* <div className="performance-box"  onClick={()=>setSelectedTimeFrame('h6')}>
                    <span className="label">6H</span>
                    <span className={price_change_percentage.m5 > 0 ? 'green' : "red"}>{price_change_percentage.h6}%</span>
                </div> */}
                <div className="performance-box"  style={{background: selectedTimeFrame === 'h24' ?  '#343439' : 'transparent'}} onClick={()=>setSelectedTimeFrame('h24')}>
                    <span className="label">24H</span>
                    <span className={price_change_percentage.h24 > 0 ? 'green' : "red"}>{price_change_percentage.h24}%</span>
                </div>
            </div>
            <div className="trade-info">
                <div className="trade-info-left">
                    <div className="trade-box">
                        <span className="label">TXNS</span>
                        <span className="value">{transactions[selectedTimeFrame].buys + transactions[selectedTimeFrame].sells}</span>
                    </div>
                    <div className="trade-box">
                        <span className="label">VOLUME</span>
                        <span className="value">${nFormatter(volume_usd[selectedTimeFrame]).slice(0,5)}</span>
                    </div>
                    <div className="trade-box">
                        <span className="label">MAKERS</span>
                        <span className="value">{transactions[selectedTimeFrame].buyers + transactions[selectedTimeFrame].sellers}</span>
                    </div>
                </div>
                <div className="trade-info-right">
                    <div className="buy-sell-info">
                        <div className="buy-sell-box">
                            <span className="label">BUYS</span>
                            <span className="value">{transactions[selectedTimeFrame].buys}</span>
                          
                        </div>
                        <div className="buy-sell-box">
                            <span className="label">SELLS</span>
                            <span className="value">{transactions[selectedTimeFrame].sells}</span>
                        </div> 
                    </div>
                    <span className="buy-sell-bar">
                        <div className="bar buy-bar" style={{ width: (100 * transactions[selectedTimeFrame].buys / (transactions[selectedTimeFrame].buys + transactions[selectedTimeFrame].sells))+'%' }}></div> 
                        <div className="bar sell-bar" style={{ width: (100 * transactions[selectedTimeFrame].sells / (transactions[selectedTimeFrame].buys + transactions[selectedTimeFrame].sells))+'%'  }}></div>
                    </span>
                       
                    {/* <div className="buy-sell-info">
                        <div className="buy-sell-box">
                            <span className="label">BUY VOL</span>
                            <span className="value">$146K</span>
                          
                        </div>
                        <div className="buy-sell-box">
                            <span className="label">SELL VOL</span>
                            <span className="value">$155K</span>
                           
                        </div>
                    </div>
                    <span className="buy-sell-bar">
                        <div className="bar buy-bar" style={{ width: '50%' }}></div> 
                        <div className="bar sell-bar" style={{ width: '50%' }}></div>
                    </span> */}
                    <div className="buy-sell-info">
                        <div className="buy-sell-box">
                            <span className="label">BUYERS</span>
                            <span className="value">{transactions[selectedTimeFrame].buyers}</span>
                            
                        </div>
                        <div className="buy-sell-box">
                            <span className="label">SELLERS</span>
                            <span className="value">{transactions[selectedTimeFrame].sellers}</span>
                          
                        </div>
                    </div>
                    <span className="buy-sell-bar">
                    <div className="bar buy-bar" style={{ width: (100 * transactions[selectedTimeFrame].buyers / (transactions[selectedTimeFrame].buyers + transactions[selectedTimeFrame].sellers))+'%' }}></div> 
                        <div className="bar sell-bar" style={{ width: (100 * transactions[selectedTimeFrame].sellers / (transactions[selectedTimeFrame].buyers + transactions[selectedTimeFrame].sellers))+'%'  }}></div>
                    </span>
                </div>
            </div>

            <div className="additional-info">
                <div className="additional-info-box">
                    <span className="label">Pair created</span>
                    <span className="value">{timeAgo(pool_created_at)}</span>
                </div>
                {/* <div className="additional-info-box">
                    <span className="label">Pooled {name}</span>
                    <span className="value">117,583,845 $634K</span>
                </div>
                <div className="additional-info-box">
                    <span className="label">Pooled TON</span>
                    <span className="value">98,171 $636K</span>
                </div> */}
                <div className="additional-info-box">
                    <span className="label">Pair</span>
                    <ShortenedValue fullValue={pool_address} link={true} linkPrefix='https://tonviewer.com/'/>

                </div>
                <div className="additional-info-box" id="address">
                    <span className="label">{name}</span>
                    <ShortenedValue fullValue={address} link={true} linkPrefix='https://tonviewer.com/'/>
                </div>
                <div className="additional-info-box">
                    <span className="label">TON</span>
                    <ShortenedValue fullValue={'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'} link={true} linkPrefix='https://tonviewer.com/' />
                </div>
                </div>
                {/* <div className="trade-platform">
                    <span>TON</span> ➔ <span>DeDust</span>
                </div> */}
        </div>
      
        <FooterToken />
    </div>
);

}

export default PoolInfo;


