import React, { useEffect, useState } from 'react';
import './index.css';
import {Link} from 'react-router-dom'
import {  fetchPools,searchPools, setSelectedPool, setPage} from '../../store/coin_gecko';
import { useSelector, useDispatch } from 'react-redux';
import { univarsalFormatNumber} from '../../utils/number';
import {FILTERS} from './constants'
import debounce from 'lodash.debounce'
import { useInView } from 'react-intersection-observer';
import Footer from '../../components/footer-main';
import { login } from '../../store/profile';

function Pools() {

    const dispatch = useDispatch();
    const pools = useSelector((state) => state.geckoReducer.data);
    const status = useSelector((state) => state.geckoReducer.status);
    const page = useSelector((state) => state.geckoReducer.page);
    const [selectedFilter, setSelectedFilter] = useState(FILTERS.trending);
    const [search, setSearch] = useState('');

    const { ref: myRef, inView: myElementIsVisible } = useInView();

  

    useEffect(() => {
        console.log({myElementIsVisible, page});
        if(myElementIsVisible && page < 10){
            dispatch(setPage(page + 1));
        }
      }, [myElementIsVisible]);

   
    useEffect(() => {
        if(search){
            dispatch(searchPools({net: 'ton', query: search}));
        }else{
            dispatch(fetchPools({net: 'ton', filter: selectedFilter , page}));
        }
    }, [dispatch, search, selectedFilter, page])

    const handleChange = debounce(query => {
        setSearch(query)
      }, 500)
  

    const hundleFilterChange = (filter) => {
        setSelectedFilter(filter)
        dispatch(setPage(1))
        dispatch(fetchPools({net: 'ton', filter}));
    }

    useEffect(()=>{
        dispatch(login(localStorage.telegramUserId))
    },[dispatch])



    // console.log(pools);
  return (
    <div className="pools">
    <header className="search-header">
      <div className="search-bar">
        <input type="text" placeholder="Search"  onChange={e => handleChange(e.target.value)}/>
      </div>
      <div className="filter-buttons">
        <button className={selectedFilter === FILTERS.trending ? 'selected_filter' : ''} onClick={()=>hundleFilterChange(FILTERS.trending)}>Trending</button>
        <button className={selectedFilter === FILTERS.gainers ? 'selected_filter' : ''} onClick={()=>hundleFilterChange(FILTERS.gainers)}>Top Pools</button>
        <button className={selectedFilter === FILTERS.new_pairs ? 'selected_filter': ''} onClick={()=>hundleFilterChange(FILTERS.new_pairs)}>New Pairs</button>
        <button className={selectedFilter === FILTERS.top_volume ? 'selected_filter': ''} onClick={()=>hundleFilterChange(FILTERS.top_volume)}>Top Volume</button>
        {/* <button className={selectedFilter == FILTERS.trending}>Filters</button>
        <button className={selectedFilter == FILTERS.trending}>5M</button> */}
      </div>
    </header>
    {status !=='loading' && <div className="volume-info">
      <span>24H VOLUME $8M</span>
      <span>24H TXNS 7,212,876</span>
    </div>}
    {status !=='loading' ? 
    <main className="token-list">
      {  pools?.data?.map((_, index) => {
        const {price_change_percentage, name, base_token_price_usd, reserve_in_usd, volume_usd, fdv_usd} = _.attributes;
        const { id } = _.relationships.dex.data
        const included_info = pools.included.find((item) => item.id === _.relationships.base_token.data.id);
        const { image_url } = included_info.attributes;
        return (
        <Link to='/pool-info' onClick={()=>dispatch(setSelectedPool({data: _, included: [included_info] }))} className="token-item" key={index}>
          <div className="token-info-list">
            <div className="token-image">
                <img className='dex-icon' src={'/assets/'+id+'.png'} alt="dex"/>
                <img src={image_url} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="https://dummyimage.com/400x400/f5f5f5/000000.png&text="+name[0];
                }} alt="token" />
            </div>
            <div className="token-name">
              <div className='token-name-name'>{ name.split(' ')[0]}</div>
              <div className="token-name-dex">{ id }</div>
            </div>
          </div>
          <div className="token-details-list">
            <div className="price">
              <span className="price-current">${univarsalFormatNumber(base_token_price_usd)}</span>
              <span className="price-change">1H <span className={price_change_percentage.h1 > 0 ? 'green': 'red'}>{univarsalFormatNumber(price_change_percentage.h1)}%</span></span>
              <span className="price-change">24H <span className={price_change_percentage.h24 > 0 ? 'green': 'red'}>{univarsalFormatNumber(price_change_percentage.h24)}%</span></span>
            </div>
            <div className="additional-info-list">
              <span>LIQ ${univarsalFormatNumber(reserve_in_usd)}</span>
              <span>VOL ${univarsalFormatNumber(volume_usd.h24)}</span>
              <span>FDV ${univarsalFormatNumber(fdv_usd) || '~'}</span>
            </div>
            {index === pools.data.length - 1 &&  <div ref={myRef} />}
          </div>
        </Link>
      )})}
       {status === 'scroll_loading' && <div className="">Loading...</div>}
    </main> : 
        <div className="loader"></div>
    }
  
    <Footer/>
  </div>
  );
}

export default Pools;
