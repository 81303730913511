import React from 'react';
import './index.css';
import {Link} from 'react-router-dom'
import { ReactComponent as LogoInfo } from '../../assets/navigation/info.svg';
import { ReactComponent as LogoChart } from '../../assets/navigation/chart.svg';

const FooterToken = () => { 
    const [isInfo, isChart] = [window.location.href.includes('pool-info'), window.location.href.includes('pool-chart')]
    const tg = window.Telegram.WebApp;

    if(isInfo){
        tg.MainButton.show()
    }
   

    return (
        <div className="footer-token">
        <Link to='/pool-info'><LogoInfo stroke={isInfo?"#fff":'#aaa'} /><div className={isInfo ? 'active_tab' : ''}>Info</div></Link>
        <Link to='/pool-chart'><LogoChart stroke={isChart?"#fff":'#aaa'} fill={isChart?"#fff":'#aaa'}/><div className={isChart ? 'active_tab' : ''}>Chart</div></Link>
     </div>
    );
};

export default FooterToken;