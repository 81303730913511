import React, { useEffect, useState } from 'react';
import './index.css';
import {Link} from 'react-router-dom'
import {  fetchPools, setStatusLoading} from '../../store/wonton-api';
import { useSelector, useDispatch } from 'react-redux';
import { univarsalFormatNumber} from '../../utils/number';
import {FILTERS} from './constants'
import debounce from 'lodash.debounce'
import { useInView } from 'react-intersection-observer';
import Footer from '../../components/footer-main';
import { login } from '../../store/profile';
import { sendTokenInfo } from '../../store/socket';

function PoolsWonton() {
    const tg = window.Telegram.WebApp;
    const dispatch = useDispatch();
    const pools = useSelector((state) => state.wontonReducer.data);
    const status = useSelector((state) => state.wontonReducer.status);
    const [selectedFilter, setSelectedFilter] = useState(FILTERS.market_cap);
    const [search, setSearch] = useState('');

    const { ref: myRef, inView: myElementIsVisible } = useInView();

   
    useEffect(() => {
      
        dispatch(fetchPools({filter: selectedFilter,}));
        
    }, [dispatch, search, selectedFilter])

    const handleChange = debounce(query => {
        setSearch(query)
      }, 500)
  

    const hundleFilterChange = (filter) => {
        setSelectedFilter(filter)
        dispatch(fetchPools({filter}));
    }

    useEffect(()=>{
        dispatch(login(localStorage.telegramUserId))
    },[dispatch])

    const onTrade = (data) => {
      const {jetton_master} = data
      const id = localStorage.telegramUserId
      dispatch(setStatusLoading(true))
      if(id){
          tg.MainButton.showProgress(true)	
          dispatch(sendTokenInfo({tokenAddress: jetton_master, telegram_id: id, wonton: data}))
          setInterval(() => {
              tg.close();
          }, 3000)
      }
  }

    // console.log(pools);
  return (
    <div className="pools">
    <header className="search-header">
      <div className="search-bar">
        <input type="text" placeholder="Search"  onChange={e => handleChange(e.target.value)}/>
      </div>
      <div className="filter-buttons">
        <button className={selectedFilter === FILTERS.market_cap ? 'selected_filter' : ''} onClick={()=>hundleFilterChange(FILTERS.trending)}>Top Market Cap</button>
        <button className={selectedFilter === FILTERS.new_pairs ? 'selected_filter': ''} onClick={()=>hundleFilterChange(FILTERS.new_pairs)}>New Pairs</button>
        <button className={selectedFilter === FILTERS.top_volume ? 'selected_filter': ''} onClick={()=>hundleFilterChange(FILTERS.top_volume)}>Top Volume</button>
        {/* <button className={selectedFilter == FILTERS.trending}>Filters</button>
        <button className={selectedFilter == FILTERS.trending}>5M</button> */}
      </div>
    </header>
    {status !=='loading' && <div className="volume-info">
      <span>24H VOLUME $8M</span>
      <span>24H TXNS 7,212,876</span>
    </div>}
    {status !=='loading' ? 
    <main className="token-list">
      {pools &&  pools?.map((_, index) => {
        const {jetton_master, high, name, ticker, extra: {image}} = _;
        return (
        <div onClick={()=>onTrade(_)} className="token-item" key={index}>
          <div className="token-info-list">
            <div className="token-image">
                {/* <img className='dex-icon' src={'/assets/'+id+'.png'} alt="dex"/> */}
                <img src={image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="https://dummyimage.com/400x400/f5f5f5/000000.png&text="+name[0];
                }} alt="token" />
            </div>
            <div className="token-name">
              <div className='token-name-name'>{ ticker }</div>
              <div className="token-name-dex">{ name }</div>
            </div>
          </div>
          <div className="token-details-list">
            <div className="price">
              <span className="price-current">{univarsalFormatNumber(high)} TON</span>
              {/* <span className="price-change">1H <span className={price_change_percentage.h1 > 0 ? 'green': 'red'}>{univarsalFormatNumber(price_change_percentage.h1)}%</span></span>
              <span className="price-change">24H <span className={price_change_percentage.h24 > 0 ? 'green': 'red'}>{univarsalFormatNumber(price_change_percentage.h24)}%</span></span> */}
            </div>
            <div className="additional-info-list">
              {/* <span>LIQ ${univarsalFormatNumber(reserve_in_usd)}</span>
              <span>VOL ${univarsalFormatNumber(volume_usd.h24)}</span>
              <span>FDV ${univarsalFormatNumber(fdv_usd) || '~'}</span> */}
            </div>
            {/* {index === pools.data.length - 1 &&  <div ref={myRef} />} */}
          </div>
        </div>
      )})}
       {status === 'scroll_loading' && <div className="">Loading...</div>}
    </main> : 
        <div className="loader"></div>
    }
  
    <Footer/>
  </div>
  );
}

export default PoolsWonton;
