import React, { useEffect, useState } from 'react';
import './App.css';
import Pools from './pages/pool-list';
import PoolsWonton from './pages/pool-list-wonton';
import PoolInfo from './pages/pool-info';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PoolsChart from './pages/pool-chart';
import Profile from './pages/profile';
import Frens from './pages/frens';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Tasks from './pages/tasks';



const App = () => {
  const [telegramUser, setTelegramUser] = useState(null);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    tg.expand();
    tg.MainButton.setParams({is_visible: false})
    tg.SettingsButton.hide()
    tg.BackButton.hide()
    tg.setBackgroundColor('#000')
    tg.setHeaderColor('#000')
    setTelegramUser(tg.initDataUnsafe?.user);
  }, []);

  useEffect(() => {
    if(telegramUser){
      localStorage.setItem('telegramUserId', telegramUser.id);
    }
  }, [telegramUser])
  return (
    <TonConnectUIProvider manifestUrl="https://mini-app.reframe.bot/tonconnect-manifest.json">
      <div className="App">
        <Router>
          <div className="app">
            <Routes>
              <Route exact path="/discovery" element={<Pools />} />
              <Route exact path="/wonton" element={<PoolsWonton />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/frens" element={<Frens/>} />
              <Route exact path="/pool-info" element={<PoolInfo />} />
              <Route exact path="/pool-chart" element={<PoolsChart />} />
              <Route exact path="/tasks" element={<Tasks />} />
              <Route path="/*" element={<Pools/>} />
            </Routes>
          </div>
        </Router>
      </div>
    </TonConnectUIProvider>

  );
}

export default App;
