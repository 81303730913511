
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Example async thunk to fetch data from an API using axios
export const sendTokenInfo = createAsyncThunk('socket/sendTokenInfo', async (data) => {
  const response = await axios.post(process.env.REACT_APP_SOCKET_HOST + '/startTrade', data, {
    headers: {
      'Access-Control-Allow-Origin': '*',
     'Access-Control-Request-Headers': 'access-control-allow-origin,content-type',
     'Access-Control-Request-Method': 'POST'
  }
  });
  return response.data;
});

const initialState = {
  value: 0,
  status: 'idle', 
  error: null,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendTokenInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendTokenInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload;
      })
      .addCase(sendTokenInfo.rejected, (state, action) => {
        // state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// export const { increment, decrement, incrementByAmount } = socketSlice.actions;

export default socketSlice.reducer;
