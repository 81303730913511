
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gecko_api from '../../utils/gecko_api';
import {TABS} from '../../pages/pool-chart/constants'
import { FILTERS } from '../../pages/pool-list/constants';
// Example async thunk to fetch data from an API using axios
export const fetchPools = createAsyncThunk('gecko/fetchPools', async ({net, filter, page = 1}) => {
  const sort =  filter === FILTERS.top_volume ? 'h24_volume_usd_desc' : 'h24_tx_count_desc'
  if( filter === FILTERS.top_volume) filter = FILTERS.gainers;

  const response = await gecko_api.get(`/onchain/networks/${net}/${filter}`, {page, include: 'base_token', sort});
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});
export const fetchPool = createAsyncThunk('gecko/fetchPool', async ({net, address}) => {
  const response = await gecko_api.get(`/onchain/networks/${net}/pools/${address}`, { include: 'base_token'});
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});

export const searchPools = createAsyncThunk('gecko/searchPools', async ({net, query}) => {
  const response = await gecko_api.get(`/onchain/search/pools`, {page: 1, network: net, query, include: 'base_token'});
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});
export const fetchOHLCV = createAsyncThunk('gecko/fetchOHLCV', async ({network, pool_address, timeframe = 'minute', timeframe_value = 5}) => {
  const response = await gecko_api.get(`/onchain/networks/${network}/pools/${pool_address}/ohlcv/${timeframe}`, {limit: 500, aggregate: timeframe_value, });
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});
export const fetchTrades= createAsyncThunk('gecko/fetchTrades', async ({network, pool_address, tab}) => {
  const response = await gecko_api.get(`/onchain/networks/${network}/pools/${pool_address}/trades`, {trade_volume_in_usd_greater_than: TABS.top_trades === tab ? 1000 : 0});
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});

export const fetchTokenInfo= createAsyncThunk('gecko/tokenInfo', async ({network, address}) => {
  const response = await gecko_api.get(`/onchain/networks/${network}/tokens/${address}/info`);
  try{
    return JSON.parse(response.data);
  }catch{
    return {data: [], included: []}
  }
});

const initialState = {
  data: {
    data: [],
    included: []
  },
  status: 'idle', 
  error: null,
  selected: {
    data:{
      attributes: {}
    },
    included: [
      {attributes: {}}
    ]
  },
  chart_data: [],
  trades: [],
  page: 1,
  token_info: {}
};

export const geckoSlice = createSlice({
  name: 'gecko_api_slice',
  initialState,
  reducers: {
    setSelectedPool: (state, action) => {
      state.selected = action.payload;
      localStorage.setItem('selected_pool', JSON.stringify(action.payload));
    },
    setStatusLoading: (state, action) => {
      state.status = action.payload ? 'loading' : 'idle';
    },
    setPage: (state, action) => {
      if(action.payload === 1){
        state.data = {
          data: [],
          included: []
        }
      }
      console.log(action.payload);
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPools.pending, (state) => {
        if(state.page === 1)
          state.status = 'loading';
        else {
          state.status = 'scroll_loading';
        }
      })
      .addCase(searchPools.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = {data: [...state.data.data, ...action.payload.data], included: [...state.data.included, ...action.payload.included]};
      })
      .addCase(searchPools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPools.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPool.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPool.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPool.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      })
      .addCase(fetchOHLCV.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chart_data = action.payload;
      })
      .addCase(fetchOHLCV.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.trades = action.payload;
      })
      .addCase(fetchTokenInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token_info = action.payload;
      })
      .addCase(fetchTrades.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTokenInfo.pending, (state) => {
        state.status = 'loading';
      });
  },
});

export const { setSelectedPool, setStatusLoading, setPage } = geckoSlice.actions;

export default geckoSlice.reducer;
