
export const TABS = {
    transaction: 'transaction',
    top_trades: 'top_trades',
}

export const TIME_FRAMES = {
    '1M': {timeframe: 'minute', timeframe_value: 1},
    '5M': {timeframe: 'minute', timeframe_value: 5},
    '15M': {timeframe: 'minute', timeframe_value: 15},
    '1H': {timeframe: 'hour', timeframe_value: 1},
    '12H': {timeframe: 'hour', timeframe_value: 12},
    '1D': {timeframe: 'day', timeframe_value: 1},
}