import React, {useState} from 'react';
import './index.css';

const Modal = ({open, title, onConfirm, onClose, balance}) => {
    
    const [amount, setAmount] = useState('');
    const [persent, setPersent] = useState('');
    const [isValid, setIsValid] = useState(false);

    const hundleChange = (value) =>{
        const check = !isNaN(value) && value > 0 && value <= balance / 1e9;
        setIsValid(check)
        setPersent('')
        setAmount(value)
    }
    const hundleClickPercent = (value) =>{
        setPersent(value)
        setAmount((balance / 1e9) * value / 100)
        setIsValid(true)
    }

    const confirm = () => {
        if(amount > balance / 1e9){
            setIsValid(false)
            return
        }
        onConfirm(amount)
    }
    return (
        <div className={`modal-overalap ${open?'open' : ''}`}>
            <div className="modal">
                <div className="modal-header">
                    <h2>{title}</h2>
                </div>
                <div className="modal-body">
                    <div className="modal-balance">Balance: {balance / 1e9} TON</div>
                    <input className='modal-input' type="text" onChange={(e)=>hundleChange(e.target.value)} value={amount} placeholder="Enter amount:"/>
                    <div className='modal-percent-btns'>
                        <div className={`percent-btn ${persent === 25 ? 'active': ''}`} onClick={() => hundleClickPercent(25)}>25%</div>
                        <div className={`percent-btn ${persent === 50 ? 'active': ''}`}  onClick={() => hundleClickPercent(50)}>50%</div>
                        <div className={`percent-btn ${persent === 75 ? 'active': ''}`}  onClick={() => hundleClickPercent(75)}>75%</div>
                        <div className={`percent-btn ${persent === 100 ? 'active': ''}`}  onClick={() => hundleClickPercent(100)}>100%</div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className='close-btn' onClick={onClose}>Close</button>
                    <button className={`confirm-btn ${!isValid ? 'disabled': ''}`} disabled={!isValid} onClick={confirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;