import { configureStore } from '@reduxjs/toolkit';
import geckoReducer from './coin_gecko';
import socketReducer from './socket';
import profileReducer from './profile';
import wontonReducer from './wonton-api';

export const store = configureStore({
  reducer: {
    geckoReducer: geckoReducer,
    wontonReducer: wontonReducer,
    socketReducer: socketReducer,
    profileReducer: profileReducer,
  },
});

export default store;