import React, {useEffect, useState} from 'react';
import './index.css';
import Footer from '../../components/footer-main';
import { ReactComponent as LogoPoints } from '../../assets/points.svg';
import { ReactComponent as LogoLarge } from '../../assets/logoLarge.svg';
import { ReactComponent as LogoRefresh } from '../../assets/refresh.svg';
import { getProfileVolume, getBlanceBot, getBlanceNative, getAddressBot, login, getAppUser } from '../../store/profile';
import { useSelector, useDispatch } from 'react-redux';
import { TonConnectButton, useTonWallet, useTonConnectUI} from '@tonconnect/ui-react';
import Modal from '../../components/modal';
// import { EvmConnectorUI} from 'evm-connector-reframe'

const Profile = () => {
    const [telegramUser, setTelegramUser] = useState(null);
    const dispatch = useDispatch();
    const volume = useSelector((state) => state.profileReducer.volume);
    const balanceNative = useSelector((state) => state.profileReducer.balanceNative);
    const balanceBot = useSelector((state) => state.profileReducer.balanceBot);
    const addressBot = useSelector((state) => state.profileReducer.addressBot);
    const token = useSelector((state) => state.profileReducer.token);
    const app_user = useSelector((state) => state.profileReducer.app_user);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [title, setTitle] = useState('Deposit to bot');
    const [open, setOpen] = useState(false);
    const [evmWallet, setEvmWallet] = useState(false);

    const onConfirm = (amount) => {
        console.log(amount);
        if(!isNaN(amount) && addressBot){
            const myTransaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: addressBot,
                        amount: amount * 1e9, // 1 TON = 1e9 nanotons
                        // stateInit: "base64bocblahblahblah==" // just for instance. Replace with your transaction initState or remove
                    },
                ]
            }
            tonConnectUI.sendTransaction(myTransaction)
        }
        // evmWallet.transferNative('0x3aB3240994Af9253Fa04E90273d7A66a069574e1', amount)
    }
    const onClose = () => {
        setOpen(false)
    }
    function refreshPage(){ 
        window.location.reload(); 
    }
    
    const wallet = useTonWallet();
    const tg = window.Telegram.WebApp;
    tg.ready();
    useEffect(() => {
        setTelegramUser(tg.initDataUnsafe?.user);    
        dispatch(getProfileVolume(localStorage.telegramUserId))
        dispatch(getAddressBot(localStorage.telegramUserId))
        dispatch(getAppUser(localStorage.telegramUserId))
        dispatch(getBlanceBot({address: '123', telegram_id: localStorage.telegramUserId}))
      }, [token]);

    useEffect(() => {
    if(wallet){
        dispatch(getBlanceNative(wallet.account.address))
    }
    }, [wallet, dispatch])
   
    useEffect(()=>{
        dispatch(login(localStorage.telegramUserId))
        function preventCollapse(event) {
            if (window.scrollY === 0) {
              window.scrollTo(0, 1);
            }
        }
        const scrollableElement = document.querySelector(".scrollable-element");
        scrollableElement.addEventListener("touchstart", preventCollapse);


        // const ui_evm = new EvmConnectorUI({
        //     buttonId: '12312312312',
        //     app_name: 'Reframe bot',
        //     successTxClb:  (hash)=>{
        //             alert('clb_hash: '+ hash)
        //         }
        //     })

        // ui_evm.transferNative('asd', 0.2)
            
        // setEvmWallet(ui_evm)
          
    },[])
    return (
        <div className='scrollable-element'>
        <div className="profile">
            <header className="profile-header">
                <h1>HELLO, {telegramUser?.first_name}</h1>

                <p>YOU HAVE:</p>
                <div className="balance">
                <div className='header-points'><LogoLarge style={{marginRight: '10px'}}/>{app_user.points} <LogoRefresh style={{marginLeft: '10px'}} onClick={refreshPage}/>  </div>
                </div>
                {/* <button className="get-more-btn">GET MORE {">"}</button> */}
                <div className='wallet-btns'>
                    <div className="wallet"><TonConnectButton className='wallet-ton' /></div>
                    <div className="wallet"><button id='12312312312'></button></div>
                    {(wallet || evmWallet) && <button className="get-more-btn" onClick={()=>setOpen(true)}>Deposit to bot</button>}
                </div>
               
            </header>

            <div className="profile-stats">
                <div className="stat">
                    <h2>Connected Wallet Balance:</h2>
                    <p className="amount">{(balanceNative / 1e9).toFixed(2)} TON</p>
                </div>
                <div className="stat">
                    <h2>ReframeBot Balance:</h2>
                    <p className="amount">{(balanceBot / 1e9).toFixed(2)} TON</p>
                </div>
            </div>
            <div className="profile-stats">
                <div className="stat">
                    <h2>Lifetime Volume</h2>
                    <p className="amount">${volume?.all?.usd?.toFixed(2)}</p>
                    <p className="conversion"><img className='ton-img' src={'/assets/ton.png'} alt="dex"/>~ {volume?.all?.base}</p>
                </div>
                <div className="stat">
                    <h2>Today's Volume</h2>
                    <p className="amount">${volume?.day?.usd?.toFixed(2)}</p>
                    <p className="conversion"><img className='ton-img' src={'/assets/ton.png'} alt="dex"/>~ {volume?.day?.base}</p>
                </div>
            </div>
            {/* <div className="trading-history"> */}
                {/* <h2>TRADING HISTORY:</h2>  */}
                {/* Trading history content goes here */}
            {/* </div> */}
            <div className='zoomer'></div>
        <Footer />
        <Modal open={open} title={title} onConfirm={onConfirm} onClose={onClose} balance={100000000000}></Modal>
        </div>
    </div>
    );
};

export default Profile;