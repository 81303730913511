
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backend_api_client from '../../utils/backend_api';

// Example async thunk to fetch data from an API using axios
export const getProfileVolume = createAsyncThunk('profile/getProfileVolume', async (telegram_id) => {
  const response = await backend_api_client.get(`/blockchain/volume/ton/${telegram_id}`);
  try{
    return JSON.parse(response.data);
  }catch{
    return { day: {
        usd: 0,
        base: 0n,
      },
      all: {
        usd: 0,
        base: 0n,
      }}
  }
});

export const login = createAsyncThunk('profile/login', async (telegram_id) => {
  const response = await backend_api_client.get(`/users/login/${telegram_id}/ton`);
  try{
    return response.data;
  }catch{
    return null
  }
});

export const countReferals = createAsyncThunk('profile/countReferals', async (telegram_id) => {
  const response = await backend_api_client.get(`/users/count/referals/${telegram_id}`);
  try{
    return response.data;
  }catch{
    return null
  }
});

export const getBlanceBot = createAsyncThunk('profile/getBlanceBot', async ({address, telegram_id}) => {
  const response = await backend_api_client.get(`/blockchain/balance/ton/${address}/${telegram_id}`);
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }
});
export const getBlanceNative = createAsyncThunk('profile/getBlanceNative', async (address) => {
  const response = await backend_api_client.get(`/blockchain/balance/ton/${address}`);
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }

});

export const getAddressBot = createAsyncThunk('profile/getAddressBot', async (telegram_id) => {
  const response = await backend_api_client.get(`/blockchain/wallet/ton/${telegram_id}/address`);
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }
});

export const getAppUser = createAsyncThunk('profile/app-users', async (telegram_id) => {
  const response = await backend_api_client.get(`/app-users`, {
    params: {
      telegram_id
    }
  });
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }
});
export const getTask = createAsyncThunk('profile/tasks', async () => {
  const response = await backend_api_client.get(`/app-users/tasks`);
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }
});
export const claim = createAsyncThunk('profile/claim', async ({telegram_id, identifier, address}) => {
  const response = await backend_api_client.get(`/app-users/task/done/${telegram_id}`, {
    params: {
      address,
      identifier
    }
  })
  try{
    return JSON.parse(response.data);
  }catch{
    return 0
  }
});

const initialState = {
    volume: {
        day: {
            usd: 0,
            base: 0,
          },
          all: {
            usd: 0,
            base: 0,
          },
    },
    balanceNative: 0,
    balanceBot: 0,
    addressBot: '',
    referalCount: 0,
    status: 'idle', 
    error: null,
    app_user: {},
    tasks: [],
    token: null
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileVolume.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfileVolume.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.volume = action.payload;
      })
      .addCase(getProfileVolume.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getBlanceNative.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBlanceNative.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.balanceNative = action.payload;
      })
      .addCase(getBlanceBot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.balanceBot = action.payload;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        localStorage.setItem('token', action.payload)
        state.token = action.payload;
      })
      .addCase(getBlanceNative.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }).addCase(getAddressBot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addressBot = action.payload.address;
      }).addCase(countReferals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.referalCount = action.payload;
      }).addCase(getAppUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.app_user = action.payload;
      
      }).addCase(getTask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasks = action.payload;
      });
  },
});

// export const { increment, decrement, incrementByAmount } = socketSlice.actions;

export default profileSlice.reducer;
