import React, { useEffect, useState } from 'react';
import './index.css';
import FooterMain from '../../components/footer-main';
import { countReferals, getAppUser, login, getTask, getProfileVolume, getBlanceBot, claim, getAddressBot} from '../../store/profile';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as LogoLarge} from '../../assets/logoLarge.svg';
import { ReactComponent as LogoSmall} from '../../assets/logoSmall.svg';
import { ReactComponent as LogoRefresh } from '../../assets/refresh.svg';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Tasks = () => {
    const dispatch = useDispatch();
    const app_user = useSelector((state) => state.profileReducer.app_user);
    const tasks = useSelector((state) => state.profileReducer.tasks);
    const token = useSelector((state) => state.profileReducer.token);
    const volume = useSelector((state) => state.profileReducer.volume);
    const balanceBot = useSelector((state) => state.profileReducer.balanceBot);
    const addressBot = useSelector((state) => state.profileReducer.addressBot);
    const [identifireMap, setIdentifireMap] = useState({})
    const [points, setPoints] = useState(0);
    const [info, setInfo] = useState({});
    useEffect(()=>{
        dispatch(countReferals(localStorage.telegramUserId))
        dispatch(getAppUser(localStorage.telegramUserId))
        dispatch(getTask())
        dispatch(getProfileVolume(localStorage.telegramUserId))
        dispatch(getAddressBot(localStorage.telegramUserId))
        dispatch(getBlanceBot({address: '123', telegram_id: localStorage.telegramUserId}))
    }, [dispatch, token])

    useEffect(()=>{
        dispatch(login(localStorage.telegramUserId))
        function preventCollapse(event) {
            if (window.scrollY === 0) {
              window.scrollTo(0, 1);
            }
          }
          const scrollableElement = document.querySelector(".scrollable-element");
          scrollableElement.addEventListener("touchstart", preventCollapse);
    }, [])
    useEffect(()=> {
        if(app_user && volume && tasks )
        tasks.map(t=>{
            if(app_user?.tasks && (app_user?.tasks[t.identifier] === 'done' || !isNaN(app_user.tasks[t.identifier]))) {
                setIdentifireMap(prev => ({...prev, [t.identifier]: 'Claimed'}));
                return 'Claimed';
            }
            else if (t.daily){
                const [ton_value] = t.identifier.split('_')
                if(+volume?.day?.base >= +ton_value){
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'Claim'}));
                }else{
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'To do'}));
                }
            }else if (t.total_volume) {
                const [ton_value] = t.identifier.split('_')
                if(+volume?.all?.base >= +ton_value){
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'Claim'}));
                }else{
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'To do'}));
                }
            }else if (t.identifier === '5_deposit_ton'){
                if(balanceBot > 5000000000){
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'Claim'}));
                }else{
                    setIdentifireMap(prev => ({...prev, [t.identifier]: 'To do'}));
                }
            }
            else if (t?.link){
                setIdentifireMap(prev => ({...prev, [t.identifier]: 'Go'}));
            }
            else if (!t.daily){
                setIdentifireMap(prev => ({...prev, [t.identifier]: 'Claim'}));
            }
            else{
                setIdentifireMap(prev => ({...prev, [t.identifier]: 'To do'}));
            }
        })
        setPoints(app_user.points)
    }, [app_user, app_user.tasks, balanceBot, tasks, volume])
    
    const onClaim = (e, task) => {
        e.preventDefault();
        dispatch(claim({telegram_id: localStorage.telegramUserId, identifier: task.identifier, address: addressBot}))
        if(task?.link && identifireMap[task.identifier] !== 'Claim'){
            window.open(task?.link);
            setTimeout(()=>{
                setIdentifireMap(prev => ({...prev, [task.identifier]: 'Claim'}));
            }, 2000)

        }else{
            setIdentifireMap(prev => ({...prev, [task.identifier]: 'Claimed'}));
            // setTimeout(()=>{
            //     refreshPage()
            // },2000)
            setPoints(prev=> prev += task.value)
            setInfo({
                open: true,
                value: task.value
            })
        }
    }
    function refreshPage(){ 
        window.location.reload(); 
    }
    console.log({balanceBot})
    return (
        <div className='scrollable-element'>

        <div className="tasks">
          
            <div className='tasks-header'>
                    <div className='header-points'><LogoLarge style={{marginRight: '10px'}}/>{points} <LogoRefresh style={{marginLeft: '10px'}} onClick={refreshPage}/>  </div>
                    <div className="header-title">Complete tasks to earn points</div>
            </div>
            <div className="tasks-block">
                <div className="header-title-3">Daily tasks: </div>
                 {tasks && tasks.filter(t=>t.daily).map((t, index)=> (
                    <div key={index} className='task'>
                        <div className='task-left'>
                            <div className='task-name'>{t.title}</div>
                            <div className='task-value'><LogoSmall style={{marginRight: '5px'}}/>{t.value}</div>
                        </div>
                        <button onClick={(e)=>onClaim(e, t)} disabled={identifireMap[t.identifier] === 'To do'}  className={identifireMap[t.identifier] === 'Claim' ? "active_claim" : ''}>{identifireMap[t.identifier]}</button>
                    </div>
                ))}
                <div className="header-title-3">One time tasks: </div>
                  {tasks && tasks.filter(t=>!t.daily).map((t,index)=> (
                    <div key={index} className='task'>
                        <div className='task-left'>
                            <div className='task-name'>{t.title}</div>
                            <div className='task-value'><LogoSmall style={{marginRight: '5px'}}/>{t.value}</div>
                        </div>
                        <button onClick={(e)=>onClaim(e, t)} disabled={identifireMap[t.identifier] === 'To do'}  className={['Claim', 'Go'].includes(identifireMap[t.identifier])  ? "active_claim" : ''}>{identifireMap[t.identifier]}</button>
                    </div>
                ))}
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                open={info.open}
                onClose={()=>setInfo({})}
                autoHideDuration={2000}
                key={'top' + 'center'}
            >
                <Alert
                    icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    You got +{info.value} points!
                </Alert>
            </Snackbar>
            <FooterMain />
        </div>
        </div>
    );
};

export default Tasks;