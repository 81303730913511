import React,  { useEffect, useState,useCallback} from 'react';
import {Link} from 'react-router-dom'
import './index.css';
import ChartComponent from '../../components/chart/index.js';
import { useSelector, useDispatch } from 'react-redux';
import {  fetchOHLCV, fetchTrades} from '../../store/coin_gecko';
import { timeAgo, formatWithSubscript } from '../../utils/number.js';
import ShortenedValue from '../../utils/copy.js'
import { TABS, TIME_FRAMES} from './constants.js';
import { sendTokenInfo } from '../../store/socket';

import FooterToken from '../../components/footer-token/index.js';


function PoolsChart() {
    const dispatch = useDispatch();
    const [selectedTab , setSelectedTab] = useState(TABS.transactions);
    const [selectedTimeFrame , setSelectedTimeFrame] = useState('15M');
    const tg = window.Telegram.WebApp;

    var { data, included } = useSelector((state) => state.geckoReducer.selected);
    let status = useSelector((state) => state.geckoReducer.status);
    if(!data?.attributes?.address){
        try{
             // eslint-disable-next-line no-redeclare
             var { data, included } = JSON.parse(localStorage.selected_pool);
        }catch{
            window.location.href = '/'
        }
    }
    const { name, image_url, address } = included[0].attributes;

    const chart_data = useSelector((state) => state.geckoReducer.chart_data);
    const trades = useSelector((state) => state.geckoReducer.trades);

    useEffect(() => {
        dispatch(fetchOHLCV({network: 'ton', pool_address: data.attributes.address, ...TIME_FRAMES[selectedTimeFrame]}));
        dispatch(fetchTrades({network: 'ton', pool_address: data.attributes.address}));
    }, [dispatch])

    const onChangeTimeFrame = (timeframe) => {
        setSelectedTimeFrame(timeframe);
        dispatch(fetchOHLCV({network: 'ton', pool_address: data.attributes.address, ...TIME_FRAMES[timeframe]}));
    }
    const onChangeTabs = (tab) => {
        setSelectedTab(tab);
        dispatch(fetchTrades({network: 'ton', pool_address: data.attributes.address, tab}));
    }
    const onTrade = () => {
      const id = localStorage.telegramUserId
      
      if(id){
          tg.MainButton.showProgress(true)	
          dispatch(sendTokenInfo({tokenAddress: address, telegram_id: id}))
          setInterval(() => {
              tg.close();
          }, 3000)
      }
  }


  const sendDataToTelegram = useCallback(() => {
    onTrade()
  }, [])

  useEffect(() => {
    window.Telegram.WebApp.onEvent('mainButtonClicked', sendDataToTelegram)
    return () => {
      window.Telegram.WebApp.offEvent('mainButtonClicked', sendDataToTelegram)
    }
  }, [sendDataToTelegram])

  return (
    status !== 'loading' ? <div className="pools-chart">
          <div className="header">
            <Link  to='/' className="back-button">←</Link>
            <div className="token-info">
                <img src={image_url.replace('/small/', '/large/')} alt="token" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="https://dummyimage.com/400x400/f5f5f5/000000.png&text="+name[0];
                }} className="token-logo" />
                <div className="token-details">
                    <h1>{name}</h1>
                    {/* <span className="rank">#1</span> */}
                    {/* <div className="token-icons">
                        <button>🌐</button>
                        <button>❌</button>
                        <button>✈️</button>
                    </div> */}
                </div>
            </div>
        </div>
        { <ChartComponent data={chart_data?.data?.attributes?.ohlcv_list || []} onChangeTimeFrame={onChangeTimeFrame}  selectedTimeFrame={selectedTimeFrame}/>}
        <div className="tabs">
          <button className={selectedTab === TABS.transactions ? 'tab active' : 'tab'} onClick={()=>onChangeTabs(TABS.transactions)}>Transactions</button>
          <button className={selectedTab === TABS.top_trades ? 'tab active' : 'tab'} onClick={()=>onChangeTabs(TABS.top_trades)}>Top Traders</button>
        </div>
        <div className="transactions">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>USD</th>
                <th>TON AMOUNT</th>
                <th>PRICE TOKEN</th>
                <th>MAKER</th>
                <th>TXN</th>
              </tr>
            </thead>
            <tbody>
                {trades?.data?.length ? trades.data.map((item, index) => {
                    const { attributes: {block_timestamp, volume_in_usd, kind, to_token_amount, from_token_amount, price_from_in_usd, price_to_in_usd, tx_from_address, tx_hash} } = item;
                    return (<tr className={kind === 'buy'? 'green':'red'}>
                        <td>{timeAgo(block_timestamp)}</td>
                        <td>${formatWithSubscript(volume_in_usd)}</td>
                        <td>{formatWithSubscript(kind === 'buy' ? from_token_amount : to_token_amount)}</td>
                        <td>{formatWithSubscript(kind === 'buy' ? price_to_in_usd  : price_from_in_usd)}</td>
                        <td><ShortenedValue fullValue={tx_from_address} start={0} /></td>
                        <td><ShortenedValue fullValue={tx_hash} start={0} link={true} linkPrefix='https://tonviewer.com/transaction/'/></td>
                      </tr>)
                    }): <tr><td colSpan="6">No transactions...</td></tr>
            }
            </tbody>
          </table>
        </div>

        <FooterToken />
  </div> :  <div className="loader"></div>
  );
}

export default PoolsChart;
