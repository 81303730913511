import React from 'react';
import './index.css';
import {Link} from 'react-router-dom'
import { ReactComponent as LogoDiscovery } from '../../assets/navigation/discover.svg';
import { ReactComponent as LogoFrens } from '../../assets/navigation/frens.svg';
import { ReactComponent as LogoUser } from '../../assets/navigation/user.svg';
import { ReactComponent as LogoTasks} from '../../assets/navigation/tasks.svg';

const FooterMain = () => {
    const [isDiscovery, isFrens, isProfile, isTasks, isWonton] = [window.location.href.includes('discovery'), window.location.href.includes('frens'), window.location.href.includes('profile'), window.location.href.includes('tasks'), window.location.href.includes('wonton')]
    const tg = window.Telegram.WebApp;
    tg.MainButton.hide()

    return (
        <div className="footer">
            <Link to='/discovery'><LogoDiscovery height='18px' stroke={isDiscovery || window.location.pathname==='/' ? "#fff" : '#aaa'}/><div className={isDiscovery ? 'active_tab': ''}>Discover</div></Link>
            <Link to='/wonton'><img className='wonton-logo' src={'/assets/wonton.png'} alt="wonton"/><div className={isWonton ? 'active_tab': ''}>Wonton</div></Link>
            <Link to='/frens'><LogoFrens height='18px' stroke={isFrens ? "#fff" : '#aaa'}/><div  className={isFrens ? 'active_tab': ''}>Referrals</div></Link>
            <Link to='/tasks'><LogoTasks   height='18px' stroke={isTasks ? "#fff" : '#aaa'}/><div  className={isTasks ? 'active_tab': ''}>Tasks</div></Link>
            <Link to='/profile'><LogoUser height='18px' stroke={isProfile ? "#fff" : '#aaa'}/> <div  className={isProfile ? 'active_tab': ''}>Profile</div></Link>
        </div>
    );
};

export default FooterMain;