import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode, LineStyle} from 'lightweight-charts';
import { TIME_FRAMES } from '../../pages/pool-chart/constants';
import './index.css';

const ChartComponent = ({data, onChangeTimeFrame, selectedTimeFrame}) => {
  const chartContainerRef = useRef();
console.log({data})
  useEffect(() => {
    const currentLocale = 'en-US';

    const myPriceFormatter = Intl.NumberFormat(currentLocale, {
        style: "currency",
        currency: "USD", // Currency for data points
        precision: 6,
        minimumFractionDigits: 6,
        maximumFractionDigits: 10,
    
      }).format;

    const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 300,
        watermark: {
            text: "Powered by TradingView",
            visible: true
        },
        layout: {
            background: { color: "#000" },
            textColor: "#C3BCDB",
            fontFamily: "'Roboto', sans-serif",
        },
        grid: {
            vertLines: { color: "#444" },
            horzLines: { color: "#444" },
        },
        timeScale: {
            borderColor: "#71649C",
            barSpacing: 10,
            timeVisible: true,
           
        },
        localization: {
            priceFormatter: myPriceFormatter,
        },
    });
   
  
 
    const candleSeries = chart.addCandlestickSeries({
        upColor: '#3ED6CC',
        downColor: '#F45B5B',
        borderDownColor: '#F45B5B',
        borderUpColor: '#3ED6CC',
        wickDownColor: '#F45B5B',
        wickUpColor: '#3ED6CC',
        priceFormat: {
            type: 'price',
            precision: 10,
            minMove: 0.0000000001,
            
        },
       

        rightPriceScale: {

            mode: 1,
            borderColor: "#71649C",
            autoScale: true, // disables auto scaling based on visible content
        
        },
        crosshair: {
            mode: CrosshairMode.Normal,

            vertLine: {
                width: 8,
                color: "#C3BCDB44",
                style: LineStyle.Solid,
                labelBackgroundColor: "#9B7DFF",
            },

            horzLine: {
                color: "#9B7DFF",
                labelBackgroundColor: "#9B7DFF",
            },
        },
      
    });

    const unique_time = []
    const parsed_data =data.map((item) => {
        const [time, open, high, low, close] = item;
        if(unique_time.includes(time)){
            return null
        }
        unique_time.push(time)
        return { time, open, high, low, close };
    }).reverse().filter((item) => item);
    
    candleSeries.setData(parsed_data)
    
    chart.timeScale().setVisibleLogicalRange({
        from: parsed_data.length - 60,
        to: parsed_data.length,
    });
   

    
    const buttonsContainer = document.createElement('div');
    buttonsContainer.classList.add('buttons-container');
    const intervals = Object.keys(TIME_FRAMES);
    intervals.forEach(interval => {
        const button = document.createElement('button');
        button.innerText = interval;
        button.addEventListener('click', () => onChangeTimeFrame(interval));
        if(selectedTimeFrame === interval) {
            button.setAttribute('class', 'selected_timeframe');
        }
        buttonsContainer.appendChild(button);
    });
    chartContainerRef.current.appendChild(buttonsContainer);
    return () => {
      chart.remove();
    };
  }, [data]);

  
  return <div ref={chartContainerRef} className="chart-container" />;
};

export default ChartComponent;
